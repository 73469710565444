import React from "react";
import Menu from "../pages/menu/index.js";
import Franchise from "../pages/franchise/index.js";
import Login from "../../public/login/index.js";
import Page404 from "../pages/page404/index.js";
import UserType from "../pages/user/userType/index.js";
import UserList from "../pages/user/userList/index.js";
import Dashboard from "../pages/dashboard/index.js";
import Admin from "../pages/franchise/admin.js";
import ErrorLog from "../pages/errorLog/index.js";
import Elements from "../pages/settings/elements/index.js";
import Settings from "../../core/settings/index.js";


const RenderPage = (page, key, user, privileges) => {
  switch (page) {
    case "login":
      return <Login key={key} />;
    case "menu":
      return <Menu key={key} {...privileges} />;
    case "franchise":
      return <Franchise key={key} {...privileges} />;
    case "user-role":
      return <UserType key={key} {...privileges} />;
    case "user-list":
      return <UserList key={key} {...privileges} />;
    case "dashboard":
      return <Dashboard key={key} {...privileges} />;
    case "admin":
      return <Admin key={key} {...privileges} />;
    case "error-log":
      return <ErrorLog key={key} {...privileges} />;
    case "elements":
      return <Elements key={key} {...privileges} />;
    case "settings":
      return <Settings key={key} {...privileges} />;
    default:
      return <Page404 key={key}></Page404>;
  }
};

export default RenderPage;
