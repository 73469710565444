import { useSelector } from "react-redux";
import { DataView, ElementContainer, MultiTabs, Title } from "../elements";
import Layout from "../layout";
import { ProfileBanner } from "./styles";
import { generateThumbnail } from "../functions/string";
import { useEffect, useState } from "react";
import ChangePassword from "./changepassword";

const ProfileSettings = (props) => {
  const themeColors = useSelector((state) => state.themeColors);
  const user = useSelector((state) => state.login);
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    setUserData(user.data.user);
  }, [user]);
  return (
    userData && (
      <ElementContainer className="dashboard">
        <ProfileBanner theme={themeColors}>
          <div className="data">
            {generateThumbnail(userData?.fullName ?? userData?.username ?? "", null, userData.photo ?? "")}
            <div className="address">
              <h2>{userData.fullName ?? "No Found"}</h2>
              <h3>{userData.email ?? "No Found"}</h3>
              <h3>{userData.userType.role ?? "No Found"}</h3>
            </div>
          </div>
        </ProfileBanner>
        <MultiTabs
          tabs={[
            {
              name: `a-unique-name-for-tab-1`,
              title: "Profile",
              element: (
                <ElementContainer className="tab-content column">
                  <Title title="Personal Details"></Title>
                  {/* <div>{JSON.stringify(userData)}</div> */}
                  <DataView title={"Name"} value={userData.fullName ?? "Not Found"}></DataView>
                  <DataView title={"Email"} value={userData.email ?? "No Found"}></DataView>
                  <DataView title={"Franchise"} value={userData.franchise ?? "No Found"}></DataView>
                  <DataView title={"Your Role"} value={userData.userType.role ?? "No Found"}></DataView>
                </ElementContainer>
              ),
              icon: "user",
            },
            {
              name: `a-unique-name-for-tab-2`,
              title: "Security Settings",
              element: (
                <ElementContainer className="tab-content">
                  <ChangePassword></ChangePassword>
                </ElementContainer>
              ),
              icon: "password",
            },
          ]}
        ></MultiTabs>
      </ElementContainer>
    )
  );
};
export default Layout(ProfileSettings);
